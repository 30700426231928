import './App.css'
import { Analytics } from '@vercel/analytics/react'
// import axios from 'axios'
import Spinner from './component/spinner/Spinner'
import { Route, Switch, Redirect } from "react-router-dom"
import { useContext, lazy, Suspense } from 'react'
import { AuthContext } from './context/authContext/AuthContext'


const Home = lazy(() => import('./scenes/home/Home'))
const NotFound = lazy(() => import('./component/404_Not Found/NotFound'))


// axios.defaults.baseURL = process.env.REACT_APP_API_URL


const App = () => {

  const { user } = useContext(AuthContext)

  return (
    <>
      <Suspense fallback={<Spinner width='40px' height='40px' />}>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
      
      <Analytics />
    </>
  )
}

export default App
